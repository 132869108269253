"use client";

import { useMemo, useState, type FC } from "react";
import { toast } from "sonner";
import { Button } from "~/components/ui/button";
import { ColorPicker } from "~/components/ui/color-picker";
import { LoadingDots } from "~/components/ui/loading-dots";
import { Switch } from "~/components/ui/switch";
import { HiInformationCircle } from "react-icons/hi2";
import { api } from "~/trpc/react";
import { type Organization } from "../model/organization-model";
export interface OrganizationMetadataFormProps {
  organization: Organization;
  className?: string;
}
export const OrganizationMetadataForm: FC<OrganizationMetadataFormProps> = ({
  organization
}) => {
  const defaultColor = "#00a359";
  const defaultButtonBg = organization.metadata?.primaryColor ?? defaultColor;
  const defaultButtonText = "#FFFFFF";
  const [color, setColor] = useState(organization.metadata?.primaryColor ?? defaultColor);
  const [buttonBgColor, setButtonBgColor] = useState(organization.metadata?.buttonBackgroundColor ?? defaultButtonBg);
  const [buttonTextColor, setButtonTextColor] = useState(organization.metadata?.buttonTextColor ?? defaultButtonText);
  const [isOutline, setIsOutline] = useState(organization.metadata?.buttonStyle === "custom-outline");
  const updateOrganizationMetadataMutation = api.organization.updateOrganizationMetadata.useMutation({
    onSuccess: () => {
      void onUpdateCompleted();
    },
    onError: () => {
      void onUpdateError();
    }
  });
  const valueHasChanged = useMemo(() => {
    return organization.metadata?.primaryColor !== color || organization.metadata?.buttonBackgroundColor !== buttonBgColor || organization.metadata?.buttonTextColor !== buttonTextColor || organization.metadata?.buttonStyle !== (isOutline ? "custom-outline" : "custom");
  }, [color, buttonBgColor, buttonTextColor, isOutline, organization.metadata]);
  async function onUpdateCompleted() {
    toast.success("Die Farben wurden erfolgreich aktualisiert. Die Seite wird in 5 Sekunden neu geladen.");
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  }
  async function onUpdateError() {
    toast.error("Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.");
  }
  function handleClear() {
    setColor(organization.metadata?.primaryColor ?? defaultColor);
    setButtonBgColor(organization.metadata?.buttonBackgroundColor ?? defaultButtonBg);
    setButtonTextColor(organization.metadata?.buttonTextColor ?? defaultButtonText);
    setIsOutline(organization.metadata?.buttonStyle === "custom-outline");
  }
  function handleClick() {
    if (updateOrganizationMetadataMutation.isPending) {
      return;
    }
    updateOrganizationMetadataMutation.mutate({
      primaryColor: color,
      buttonBackgroundColor: buttonBgColor,
      buttonTextColor: buttonTextColor,
      buttonStyle: isOutline ? "custom-outline" : "custom"
    });
  }
  return <div className="flex flex-col gap-4 pt-2" data-sentry-component="OrganizationMetadataForm" data-sentry-source-file="organization-metadata-form.tsx">
      <div>
        <div className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
          Akzentfarbe
        </div>
        <div className="mb-4 mt-2 text-sm text-muted-foreground">
          <ColorPicker onChange={v => {
          setColor(v);
        }} value={color} className="h-20 w-20" data-sentry-element="ColorPicker" data-sentry-source-file="organization-metadata-form.tsx" />
        </div>
        <div className="mb-2 flex flex-row items-start justify-start gap-2 text-xs text-muted-foreground">
          <HiInformationCircle className="h-4 w-4 shrink-0" data-sentry-element="HiInformationCircle" data-sentry-source-file="organization-metadata-form.tsx" /> Mit der
          Akzentfarbe können Sie Ihrem ClimateHub einen persönlichen Eindruck
          verleihen. Die Akzentfarbe wird auf der ganzen Plattform verwendet, um
          wichtige Elemente wie Links, Buttons, und mehr hervozuheben.
        </div>
      </div>

      <div>
        <div className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
          Button-Farben
        </div>
        <div className="mb-4 mt-2 flex gap-4">
          <div>
            <div className="mb-1 text-xs text-muted-foreground">
              Hintergrund
            </div>
            <ColorPicker onChange={v => {
            setButtonBgColor(v);
          }} value={buttonBgColor} className="h-20 w-20" data-sentry-element="ColorPicker" data-sentry-source-file="organization-metadata-form.tsx" />
          </div>
          <div>
            <div className="mb-1 text-xs text-muted-foreground">Text</div>
            <ColorPicker onChange={v => {
            setButtonTextColor(v);
          }} value={buttonTextColor} className="h-20 w-20" data-sentry-element="ColorPicker" data-sentry-source-file="organization-metadata-form.tsx" />
          </div>
          <div className="flex flex-col justify-center">
            <div className="mb-1 text-xs text-muted-foreground">Vorschau</div>
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <Switch checked={isOutline} onCheckedChange={setIsOutline} data-sentry-element="Switch" data-sentry-source-file="organization-metadata-form.tsx" />
                <span className="text-xs text-muted-foreground">
                  Outline Style
                </span>
              </div>
              <div className={`rounded-md px-3 py-2 text-sm transition-colors`} style={isOutline ? {
              color: buttonBgColor,
              backgroundColor: "transparent",
              border: `1px solid ${buttonBgColor}`
            } : {
              backgroundColor: buttonBgColor,
              color: buttonTextColor
            }}>
                Button Vorschau
              </div>
            </div>
          </div>
        </div>
        <div className="mb-2 flex flex-row items-start justify-start gap-2 text-xs text-muted-foreground">
          <HiInformationCircle className="h-4 w-4 shrink-0" data-sentry-element="HiInformationCircle" data-sentry-source-file="organization-metadata-form.tsx" /> Passen Sie die
          Farben der Buttons an. Wenn keine Button-Farben ausgewählt sind, wird
          die Akzentfarbe verwendet.
        </div>
      </div>

      <div className="flex flex-row items-center gap-2 py-4">
        <Button type="button" size="sm" variant="ghost" className="w-24 rounded-full hover:border-[0.5px]" disabled={updateOrganizationMetadataMutation.isPending || !valueHasChanged} onClick={handleClear} data-sentry-element="Button" data-sentry-source-file="organization-metadata-form.tsx">
          Verwerfen
        </Button>

        <Button type="submit" disabled={updateOrganizationMetadataMutation.isPending || !valueHasChanged} size="sm" variant="ghost" onClick={handleClick} className={`${updateOrganizationMetadataMutation.isPending ? "cursor-not-allowed bg-muted" : ""} flex h-10 w-24 items-center justify-center rounded-full border-[0.5px] bg-[#FCFAF8] transition-all`} data-sentry-element="Button" data-sentry-source-file="organization-metadata-form.tsx">
          {updateOrganizationMetadataMutation.isPending ? <LoadingDots /> : <span>Speichern</span>}
        </Button>
      </div>
    </div>;
};