"use client";

import { useEffect, type FC } from "react";
import { hexToHSL } from "~/lib/utils";
export interface OrganizationThemeLoaderProps {
  theme: {
    primaryColor: string;
    buttonBackgroundColor?: string;
    buttonTextColor?: string;
    buttonStyle?: string;
  };
}
export const OrganizationThemeLoader: FC<OrganizationThemeLoaderProps> = ({
  theme
}) => {
  // Note: Due to bundling and caching optimization there is not really a good way
  // to generate CSS on the server and dynamically return it to the client.
  //
  // Instead this component, runs once on the client and globally overrides the
  // respective CSS variables (see ~/styles/globals.css) with the provided color
  // from the theme.

  useEffect(() => {
    const primaryHsl = hexToHSL(theme.primaryColor);
    const buttonBgHsl = theme.buttonBackgroundColor ? hexToHSL(theme.buttonBackgroundColor) : primaryHsl;
    const buttonTextHsl = theme.buttonTextColor ? hexToHSL(theme.buttonTextColor) : null;
    if (!primaryHsl || !buttonBgHsl) {
      return;
    }
    const primaryHslList = primaryHsl.array();
    const buttonBgHslList = buttonBgHsl.array();
    const buttonTextHslList = buttonTextHsl?.array();
    if (!primaryHslList || primaryHslList.length < 3 || !buttonBgHslList || buttonBgHslList.length < 3) {
      return;
    }
    const primaryHslString = `${primaryHslList[0]} ${primaryHslList[1]}% ${primaryHslList[2]}%`;
    const buttonBgHslString = `${buttonBgHslList[0]} ${buttonBgHslList[1]}% ${buttonBgHslList[2]}%`;
    const buttonTextHslString = buttonTextHslList ? `${buttonTextHslList[0]} ${buttonTextHslList[1]}% ${buttonTextHslList[2]}%` : "0 0% 100%"; // Default white text

    const cssText = [`--primary: ${primaryHslString}`, `--ring: ${primaryHslString}`, `--button-bg: ${buttonBgHslString}`, `--button-text: ${buttonTextHslString}`, `--button-style: ${theme.buttonStyle ?? "custom"}`].join(";");
    const root = document.documentElement;
    root.style.cssText = cssText;
  }, [theme]);
  return <></>;
};